// INFO: Les couleurs
$white: #FFFFFF;
$white-accent: #FFFDF7;
$black: #001A38;
$color: #050507;
$error: #CF0C1F;
$input-error: #EB5757;

$default-color: #DA3832;
$default-color-hover: #ce2c27;
$default-color-alpha: #f45650;

$secondary-color: #ffffff;
$secondary-color-hover: rgb(229, 229, 229);
$light-blue: #59A3D8;

$box-shadow: rgba(0, 0, 0, .25);
$light-background: rgba(255, 255, 255, .1);